*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 16px;
	--color-text: #333;
	--color-bg: #f5e7d4;
	--color-link: #333;
	--color-link-hover: #888;
	--page-padding: 1.5rem 2rem;
}

body {
	margin: 0;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: area-normal,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

.demo-1 {
background-image: linear-gradient(
  180deg,
  hsl(0deg 0% 100%) 0%,
  hsl(41deg 34% 95%) 10%,
  hsl(41deg 34% 90%) 20%,
  hsl(41deg 33% 85%) 30%,
  hsl(42deg 33% 81%) 40%,
  hsl(42deg 32% 76%) 50%,
  hsl(40deg 33% 75%) 60%,
  hsl(39deg 33% 74%) 70%,
  hsl(37deg 33% 74%) 80%,
  hsl(36deg 33% 73%) 90%,
  hsl(34deg 33% 72%) 100%
);
}

.demo-2 {
	--color-text: #ddc4a4;
    --color-bg: #0e0e0e;
    --color-link: #ede3e3;
    --color-link-hover: #d68521;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
	cursor: pointer;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
	cursor: pointer;
}

.unbutton:focus {
	outline: none;
}

main {
	counter-reset: section;
}

.splitting .word {
	white-space: nowrap;
}

.frame {
	color: var(--color-title);
	padding: var(--page-padding);
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto auto auto;
	grid-template-areas: 'title' 'prev' 'demos' 'sponsor';
	justify-content: start;
	justify-items: start;
	margin-bottom: 1.5rem;
	grid-gap: 0.5rem;
	align-items: start;
	font-weight: 600;
	font-size: 14px;
	border-bottom:1px solid #eee;
}

.frame a:not(.frame__title-back),
.line {
	white-space: nowrap;
	overflow: hidden;
	position: relative;
}

.frame a:not(.frame__title-back)::before,
.line::before {
	content: '';
	height: 1px;
	width: 100%;
	background: currentColor;
	position: absolute;
	top: 90%;
	transition: transform 0.3s;
	transform-origin: 0% 50%;
}

.frame a:not(.frame__title-back):hover::before,
.line:hover::before {
	transform: scaleX(0);
	transform-origin: 100% 50%;
}

.frame__title {
	grid-area: title;
	display: flex;
	align-items: center;
}

.frame__title-main {
	font-size: 1rem;
	margin: 0;
	font-weight: normal;
}

.frame__title-back {
	position: relative;
	display: flex;
	align-items: flex-end;
	padding:5px 10px;
}

.nav-buy{
align-self: flex-end;
margin-left:20px;
}

a.btn{
	background:#333;
	color:#fff;
	padding:5px 15px;
	border-radius: 20px;
}

a.btn:hover{
	background:#888;
	color:#eee;
}

.frame__logo{
	font-size:1.2rem;
	font-weight:800;
}

.frame__title-back svg {
	fill: currentColor;
}

.frame__prev {
	grid-area: prev;
	align-self: start;
}

.frame__demos {
	display: flex;
	gap: 1rem;
	padding:5px;
}

.frame__demos span {
	opacity: 0.9;
}

.intro {
	/*height: 100vh;*/
	display: grid;
	/*place-items: center;*/
	/*text-align: center;*/
 	/*grid-template-columns: repeat(auto-fill, minmax(min(50rem), 1fr));
  	column-gap: 10px;
  	row-gap: 15px;*/
  	border-bottom:3px solid #444;
  	background-image: url(../img/snp_command3.png);
  	background-size:100% auto;
  	background-repeat: no-repeat;
  	background-position: bottom left;
  	height:90vh;
}

.intro__image{
	display:block;
	line-height: 0;
	z-index:-1;
}

.intro-bg{
		border-bottom:3px solid #444;
  	background-image: url(../img/snp_command3.png);
  	background-size:100% auto;
  	background-repeat: no-repeat;
  	background-position: bottom left;
  	  	height:80vh;

}

.intro-wrap{
		background:rgba(247, 244, 238, 0.9);
		margin:0 auto;
		width:auto;
		border-radius: 20px;
		padding:0 40px 40px 40px;
		text-align: center;
		max-width: 840px;
}

.intro__image img{
	width:100%;
	height:auto;
	max-width:1920px;
}

.intro__logo{
	max-width:120px;
	margin-bottom:40px;
}

.intro__title_wrap{
	display: grid;
	place-items: center;
	text-align: center;
}

.intro__title {
	display: grid;
	place-items: center;
	margin: 0;
	line-height: 1.2;
	font-weight: 600;
	max-width:960px;
	margin-top:8%;
	padding:0 20px;

}


.buy-book{
	margin:0 auto;
	display:inline-block;
	text-align:center;
}


.intro__title-pre {
	font-family: Baskerville, serif;
	font-weight: 600;
	/*text-transform: uppercase;*/
	font-size: clamp(1rem,8vw,7rem);
	line-height: 1;
}

.intro__title-sub {
	font-size: clamp(.50rem,5.50vw,2rem);
	background:rgba(247, 244, 238, 0.9);
	padding:30px 20px;
	/*border:3px solid #444;*/
	border-radius: 8px;
}

.intro__info {
	position: relative;
	max-width: 20ch;
	opacity: 0.8;
	line-height: 1.2;
	margin-bottom: 3rem;
	margin-top:2rem;
	padding-bottom: 1rem;
	font-weight: 600;
	font-size: 20px;
}

.intro__info::after {
	content: '';
	position: absolute;
	left: 50%;
	width: 1px;
	height: 2rem;
	background: #666;
	top: 100%;
}

.luna-image{
	max-width: 500px;
    margin: 0 auto;
    width: 100%;
}

.content {
	display: flex;
	flex-direction: column;
	width: 100vw;
	position: relative;
	padding: var(--page-padding);
}

.content:last-child {
	padding-bottom: 40vh;
}

.content--full {
	padding: 0;
}

.content p {
	max-width: 660px;
	margin: 1.5rem auto;
	font-size: 1.25rem;
	line-height: 1.5;
	font-weight: 600;
}

.content--highlight p {
	font-size: clamp(1rem,3vw,2rem);
}

.content__title {
	font-size: 12vw;
	line-height: 0.8;
	counter-increment: section;
	text-align: center;
	display: grid;
	gap: 2rem;
	margin-bottom:3rem;
}


.content__title--small{
	font-size:5vw;
}

.verysmall{
	font-size:5vw;
	line-height:1.2;
}


.content__title--left {
	text-align: left;
}

.content__title--right {
	text-align: right;
}

.content__title::before {
	/*content: '.'counter(section);
	font-weight: 400;
	font-family: area-normal, sans-serif;
	display: block;
	font-size: 1rem;
	margin-bottom: 10vh;
	text-align: center;*/
}

.content__title--sides {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 100%;
	text-align: left;
	gap: 2rem;
}

.content__title--sides span:last-child {
	justify-self: end;
}

.font-1 {
	font-family: wayfinder-cf, serif;
	font-weight: 100;
}

.font-2 {
	font-family: campaign-serif, sans-serif;
	font-weight: 400;
}

.font-3 {
	font-family: area-normal, sans-serif;
	font-weight: 600;
}

.font-4 {
	font-family: campaign-serif, sans-serif;
	font-weight: 400;
	font-style: italic;
}

.font-5 {
	font-family: zedou, sans-serif;
	font-weight: 300;
	text-transform: uppercase;
}

.font-6 {
	font-family: afronaut, sans-serif;
	font-weight: 400;
}

.font-7 {
	font-family: hoss-round-wide, sans-serif;
	font-weight: 400;
}

.font-8 {
	font-family: monotalic, sans-serif;
	font-weight: 400;
	text-transform: lowercase;
}

.font-9 {
	font-family: aziga, sans-serif;
	font-weight: 400;
}

.font-10 {
	font-family: norman-stencil, sans-serif;
	font-weight: 800;
}

.font-11 {
	font-family: neighbor, sans-serif;
	font-weight: 400;
}

.font-12 {
	font-family: halogen, sans-serif;
	font-weight: 700;
}

.font-13 {
	font-weight: initial;
	font-family: "marlide-display-variable", sans-serif;
	font-variation-settings: "wght" 400;
}

.font-14 {
	font-weight: initial;
	font-family: "june-expt-variable", sans-serif;
	font-variation-settings: "STYL" 0;
}

.font-15 {
	font-weight: initial;
	font-family: "june-expt-variable",sans-serif;
	font-variation-settings: 'STYL' 100;
}

.font-16 {
	font-weight: initial;
	font-family: "inge-variable", sans-serif;
	font-variation-settings: "slnt" 0;
}

.font-17 {
	font-weight: initial;
	font-family: "antipoda-variable", sans-serif;
	font-variation-settings: "wght" 450, "wdth" 80;
}

.font-18 {
	font-weight: initial;
	font-family: "vinila-variable", sans-serif;
	font-variation-settings: "slnt" 0, "wght" 100, "wdth" 100;
}

.font-19 {
	font-weight: initial;
	font-family: "nagel-variable", sans-serif;
	font-variation-settings: "slnt" 0, "wght" 610;
}

.font-20 {
	font-weight: initial;
	font-family: "borax-variable", sans-serif;
	font-variation-settings: "wght" 400;
}

.font-21 {
	font-family: "gigantic", sans-serif;
	font-weight: 900;
	font-style: normal;
}

.font-22 {
	font-family: "capsule", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.font-23 {
	font-family: "arsenica-variable", sans-serif;
	font-variation-settings: "opsz" 1000, "wght" 300;
}

.font-height {
	line-height: 1.4;
}

.font-height-medium {
	line-height: 0.9;
}

.font-medium {
	font-size: 6vw;
	margin-top: 3rem;
}

.font-larger {
	font-size: 27vw;
}

.font-upper {
	text-transform: uppercase;
}

.font-sub {
	font-size: 5vw;
	margin-top: 2rem;
    display: block;
}

.splitting .font-sub .word {
	display: grid;
	grid-auto-flow: column;
}

.char-wrap {
	display: inline-block;
	position: relative;
	overflow: hidden;
}

img.networkcards, img.author-photo{
	display:block;
	width:100%;
	height:auto;
	max-width:1100px;
	margin:0 auto;
}

img.author-photo{
	max-width:900px;
	border-radius: 10px;
	border:3px solid #444;
}

@media screen and (min-width: 53em) {
	.frame {
		/*justify-items: start;
		grid-gap: 2rem;
		grid-template-columns: auto auto 1fr auto;
		grid-template-rows: auto;
		grid-template-areas: 'title prev demos sponsor';*/
		display: flex;
	}

	.frame__demos {
		justify-self: end;
		margin-left:auto;
	}
}


@media screen and (max-width:1200px){
	.content__title--small{
	font-size:6vw;
	}

}


@media screen and (max-width:800px){
	.intro__logo{
		max-width:90px;
		margin-bottom:20px;
	}

	.content__title--small{
	font-size:8vw;
	}

	.frame__demos{
		display: none;
	}

	.frame{
			padding:10px 20px;
			margin-bottom: 10px;
			display: block;
	}
	.intro-bg{
		height:auto;
		padding-bottom:30px;
	}

	.intro-wrap{
		background:rgba(247, 244, 238, 0.5);
		margin:0 auto;
		width:auto;
		border-radius: 20px;
		padding:0 10px 40px 10px;
		text-align: center;
}
}
